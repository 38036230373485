import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },

    //MB - removed /dashboard - 28 July 22
  {
    path: "/",
    name: "default",
    meta: {
      title: "Dashboard",
      authRequired: true,
    },
    component: () => import("../views/dashboard/projects/index"),
  },



  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout", authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {

          store.dispatch("authfack/logout");

        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute ? { name: "default" } : { ...routeFrom }
        );
      },
    },
    component: () => import("@/views/auth/logout/basic")
  },


  // {
  //   path: "/apps/crm-contacts",
  //   name: "crm-contacts",
  //   meta: { title: "CRM Contacts", authRequired: true },
  //   component: () => import("../views/apps/crm/contacts"),
  // },
  // {
  //   path: "/apps/crm-companies",
  //   name: "crm-companies",
  //   meta: { title: "CRM companies", authRequired: true },
  //   component: () => import("../views/apps/crm/companies"),
  // },
  // {
  //   path: "/apps/crm-deals",
  //   name: "crm-deals",
  //   meta: { title: "CRM Deals", authRequired: true },
  //   component: () => import("../views/apps/crm/deals"),
  // },
  // {
  //   path: "/apps/crm-leads",
  //   name: "crm-leads",
  //   meta: { title: "CRM Leads", authRequired: true },
  //   component: () => import("../views/apps/crm/leads"),
  // },

  // {
  //   path: "/invoices/detail/:id?",
  //   name: "invoice-detail",
  //   meta: { title: "Invoice Details", authRequired: true },
  //   component: () => import("../views/apps/invoices/details"),
  // },
  {
    path: "/clients/list",
    name: "clients-list",
    meta: { title: "Client List", authRequired: true },
    component: () => import("@/views/clients/ClientList.vue"),
  },
  {
    path: "/product/list",
    name: "products-list",
    meta: { title: "Product List", authRequired: true },
    component: () => import("@/views/products/ProductList.vue"),
  },
  {
    path: "/product/categories",
    name: "products-categories",
    meta: { title: "Product categories", authRequired: true },
    component: () => import("@/views/products/ProductCategories.vue"),
  },
  {
    path: "/product/brands",
    name: "products-brands",
    meta: { title: "Product brands", authRequired: true },
    component: () => import("@/views/products/ProductBrands.vue"),
  },
  {
    path: "/invoices/list",
    name: "invoice-list",
    meta: { title: "Invoice List", authRequired: true },
    component: () => import("../views/apps/invoices/list"),
  },
  {
    path: "/invoices/create",
    name: "invoice-create",
    meta: { title: "Create Invoice", authRequired: true },
    component: () => import("../views/apps/invoices/create"),
  },
  {
    path: "/vendor/list",
    name: "vendor-list",
    meta: { title: "Vendor List", authRequired: true },
    component: () => import("../views/vendors/VendorList.vue"),
  },
  {
    path: "/vendor-quotes",
    name: "vendor-quotes",
    meta: { title: "Vendor Quotes", authRequired: true },
    component: () => import("../views/vendors/VendorQuotes.vue"),
  },
  {
    path: "/currency/list",
    name: "currency-list",
    meta: { title: "Currency List", authRequired: true },
    component: () => import("../views/currency/CurrencyList.vue"),
  },
  {
    path: "/user/list",
    name: "user-ist",
    meta: { title: "List", authRequired: true },
    component: () => import("../views/users/UsersLists.vue"),
  },
  {
    path: "/user/roles",
    name: "user-rolse",
    meta: { title: "Roles", authRequired: true },
    component: () => import("../views/users/UserRoles.vue"),
  },
  {
    path: "/request-for-quotes",
    name: "Request-for-Quotes",
    meta: { title: "Request For Quotes", authRequired: true },
    component: () => import("../views/rfq/RFQList.vue"),
  },
  {
    path: "/request-for-quotes/add",
    name: "add-request-for-quotes",
    meta: { title: "Add RFQ", authRequired: true },
    component: () => import("../views/rfq/AddRFQ.vue"),
  },
  {
    path: "/request-for-quotes/:id/update",
    name: "update-request-for-quotes",
    meta: { title: "Update RFQ", authRequired: true },
    component: () => import("../views/rfq/UpdateRFQ.vue"),
  },
  {
    path: "/request-for-quotes-details/:id",
    name: "Request-for-Quotes-Detail",
    meta: { title: "Request For Quotes Detail", authRequired: true },
    component: () => import("../views/rfq/RFQDetails.vue"),
  },
  // {
  //   path: '/auth/signin-basic',
  //   name: 'signin-basic',
  //   meta: {
  //     title: "Signin Basic", authRequired: true,
  //   },
  //   component: () => import('@/views/auth/signin/basic')
  // },
  // {
  //   path: '/auth/signin-cover',
  //   name: 'signin-cover',
  //   meta: {
  //     title: "Signin Cover", authRequired: true,
  //   },
  //   component: () => import('@/views/auth/signin/cover')
  // },
  // {
  //   path: '/auth/signup-basic',
  //   name: 'signup-basic',
  //   meta: {
  //     title: "Signup Basic", authRequired: true,
  //   },
  //   component: () => import('@/views/auth/signup/basic')
  // },
  // {
  //   path: '/auth/signup-cover',
  //   name: 'signup-cover',
  //   meta: {
  //     title: "Signup Cover", authRequired: true,
  //   },
  //   component: () => import('@/views/auth/signup/cover')
  // },
  // {
  //   path: '/auth/reset-pwd-basic',
  //   name: 'reset-pwd-basic',
  //   meta: {
  //     title: "Reset Password", authRequired: true,
  //   },
  //   component: () => import('../views/auth/reset/basic')
  // },
  // {
  //   path: '/auth/reset-pwd-cover',
  //   name: 'reset-pwd-cover',
  //   meta: {
  //     title: "Reset Password", authRequired: true,
  //   },
  //   component: () => import('../views/auth/reset/cover')
  // },
  // {
  //   path: '/auth/create-pwd-basic',
  //   name: 'create-pwd-basic',
  //   meta: {
  //     title: "create Password", authRequired: true,
  //   },
  //   component: () => import('../views/auth/create/basic')
  // },
  // {
  //   path: '/auth/create-pwd-cover',
  //   name: 'create-pwd-cover',
  //   meta: {
  //     title: "create Password", authRequired: true,
  //   },
  //   component: () => import('../views/auth/create/cover')
  // },
  // {
  //   path: '/auth/lockscreen-basic',
  //   name: 'lock-screen-basic',
  //   meta: {
  //     title: "Lock Screen", authRequired: true,
  //   },
  //   component: () => import('../views/auth/lockscreen/basic')
  // },
  // {
  //   path: '/auth/lockscreen-cover',
  //   name: 'lock-screen-cover',
  //   meta: {
  //     title: "Lock Screen", authRequired: true,
  //   },
  //   component: () => import('../views/auth/lockscreen/cover')
  // },
  // {
  //   path: '/auth/twostep-basic',
  //   name: 'twostep-basic',
  //   meta: {
  //     title: "Two Step Auth", authRequired: true,
  //   },
  //   component: () => import('../views/auth/twostep/basic')
  // },
  //  {
  //   path: '/auth/twostep-cover',
  //   name: 'twostep-cover',
  //   meta: {
  //     title: "Two Step Auth", authRequired: true,
  //   },
  //   component: () => import('../views/auth/twostep/cover')
  // },
  {
    path: '/auth/404',
    name: '404',
    meta: {
      title: "Error 404", authRequired: true,
    },
    component: () => import('../views/auth/errors/404')
  },
  {
    path: '/auth/500',
    name: '500',
    meta: {
      title: "Error 500", authRequired: true,
    },
    component: () => import('../views/auth/errors/500')
  },
  {
    path: '/auth/404-basic',
    name: '404-basic',
    meta: {
      title: "Error 404", authRequired: true,
    },
    component: () => import('../views/auth/errors/404-basic')
  },
  {
    path: '/auth/404-cover',
    name: '404-cover',
    meta: {
      title: "Error 404", authRequired: true,
    },
    component: () => import('../views/auth/errors/404-cover')
  },
  {
    path: '/auth/ofline',
    name: 'ofline',
    meta: {
      title: "Oflone", authRequired: true,
    },
    component: () => import('../views/auth/errors/ofline')
  },
  {
    path: '/auth/logout-basic',
    name: 'logout-basic',
    meta: {
      title: "Logout", authRequired: true,
    },
    component: () => import('../views/auth/logout/basic')
  },
  {
    path: '/auth/logout-cover',
    name: 'logout-cover',
    meta: {
      title: "Logout", authRequired: true,
    },
    component: () => import('../views/auth/logout/cover')
  },
  // {
  //   path: '/auth/success-msg-basic',
  //   name: 'success-msg-basic',
  //   meta: {
  //     title: "Success Message", authRequired: true,
  //   },
  //   component: () => import('../views/auth/success-msg/basic')
  // },
  // {
  //   path: '/auth/success-msg-cover',
  //   name: 'success-msg-cover',
  //   meta: {
  //     title: "Success Message", authRequired: true,
  //   },
  //   component: () => import('../views/auth/success-msg/cover')
  // },

];
