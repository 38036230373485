import { ApiService } from "@/services/ApiService";

export default {
  getRFQs(options) {
    return ApiService.get(
      `/api/rfqs-list?page=${options.current_page}&page_size=${options.page_size}&search_text=${options.search_text}&sort_column=${options.sort.column}&sort_direction=${options.sort.direction}`
    );
  },
  getRFQ(id) {
    return ApiService.get(`/api/rfqs/${id}`);
  },
  addRFQ(payload) {
    return ApiService.post("/api/rfqs", payload);
  },

  assignRFQs(payload) {
    return ApiService.post("/api/rfq-detail/assign/users", payload);
  },

  assignRFQUsers(payload) {
    return ApiService.post("/api/rfq/assign/users", payload);
  },

  updateRFQ(payload) {
    return ApiService.put("/api/rfqs/" + payload.id, payload);
  },

  deleteRFQ(payload) {
    return ApiService.delete("/api/rfqs/" +  payload.id,payload.data);
  },

  deleteMutipleRFQs(rfqs_ids) {
    return ApiService.post("/api/rfqs/delete/bulk", {
      selected_ids: rfqs_ids,
    });
  },
  //RFQ NOTES
  getRFQStatusList() {
    return ApiService.get('/api/rfq-status-list')
  },

  

  //RFQ Response
  getRFQsResponse(options) {
    return ApiService.get(
      `/api/rfq-responses-list?page=${options.current_page}&page_size=${options.page_size}&search_text=${options.search_text}&sort_column=${options.sort.column}&sort_direction=${options.sort.direction}&rfq_id=${options.rfqs_id}`
    );
  },

  //RFQ Documents
  getRFQsDocs(options) {
    return ApiService.get(
      `/api/rfq-documents-list?page=${options.current_page}&page_size=${options.page_size}&search_text=${options.search_text}&sort_column=${options.sort.column}&sort_direction=${options.sort.direction}&rfq_id=${options.rfqs_id}`
    );
  },
};
