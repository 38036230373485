import { AuthService } from "../../services/AuthService";
import router from "../../router";


const user = localStorage.getItem("jwt")
export const state = {
    user: user ? {
        status: {
          loggeduser: true,
        },
        user,
        error: null,
        authenticated:true,
      }
    : {
        status: {},
        user: null,
        error: null,
        authenticated:false,
      },
}

export const mutations = {
    loginRequest(state, user) {
        state.status = {
          loggingIn: true,
        };
        state.user = user;
      },
      loginSuccess(state, user) {
        state.status = {
          loggeduser: true,
        };
        state.user = user;
      },
      loginFailure(state) {
        state.status = {};
        state.user = null;
      },
      logout(state) {
        state.status = {};
        state.user = null;
      },
}

export const getters = {
    error: (state) => state.error,
    loading: (state) => state.loading,
}

export const actions = {

 // eslint-disable-next-line no-unused-vars
 login({ dispatch, commit }, payload) {
    commit("loginRequest", payload.email);

    AuthService.login(payload).then(
      (user) => {
          console.log(user)
        commit("loginSuccess", user);

          //MB - removed /dashboard - 28 July 22
        router.push("/");
      },
      (error) => {
        commit("loginFailure", error);
        //TODO - Notification
        // dispatch("notification/error", error, {
        //     root: true,
        // });
      }
    );
  },

  logout({ commit }) {
    AuthService.logout();
    commit("logout");
  },

}
