import { getError } from "../../helpers/errors"
import CurrencyService from "../../services/CurrencyService"



export const state = {
    loading: false,
    error:null,
    currency:[]
}


export const actions ={
    getCurrencies({commit},options){
        commit("SET_LOADING", true)
        return new Promise((resolve,reject)=>{
            CurrencyService.getCurrencies(options)
            .then(({data})=>{
                commit("SET_CURRENCY",data)
                resolve(data)
            })
            .catch((err)=>{
                commit("SET_ERROR", getError(err));
                reject(err);
            })
            .finally(()=> commit("SET_LOADING", false))
        })
    },

    addCurrency({commit},payload){
        commit("SET_LOADING", true)
        return new Promise((resolve,reject)=>{
            CurrencyService.addCurrency(payload)
            .then(({data})=>{
                resolve(data)
            })
            .catch((err)=>{
                commit("SET_ERROR", getError(err));
                reject(err);
            })
            .finally(()=> commit("SET_LOADING", false))
        })
    },

    editCurrency({commit},payload){
        commit("SET_LOADING", true)
        return new Promise((resolve,reject)=>{
            CurrencyService.editCurrency(payload)
            .then(({data})=>{
                resolve(data)
            })
            .catch((err)=>{
                commit("SET_ERROR", getError(err));
                reject(err);
            })
            .finally(()=> commit("SET_LOADING", false))
        })
    },

    deleteCurrency({commit},id){
        commit("SET_LOADING", true)
        return new Promise((resolve,reject)=>{
            CurrencyService.deleteCurrency(id)
            .then(({data})=>{
                resolve(data)
            })
            .catch((err)=>{
                commit("SET_ERROR", getError(err));
                reject(err);
            })
            .finally(()=> commit("SET_LOADING", false))
        })
    },

    deleteMultipleCurrencies({ commit }, clients_ids) {
        commit("SET_LOADING", true);
        return new Promise((resolve, reject) => {
          CurrencyService.deleteMultipleProducts(clients_ids)
            .then(({ data }) => {
              resolve(data);
            })
            .catch((err) => {
              reject(getError(err));
            });
        });
      },
}


export const mutations ={
    SET_LOADING(state, loading) {
        state.loading = loading;
      },
    
      SET_ERROR(state, error) {
        state.error = error;
      },
      SET_CURRENCY(state,data){
        state.vendors = data
      },
     
}

export const getters ={
    loading: (state) => state.loading,
    error: (state) => state.error,
    vendors: (state) => state.vendors,
}