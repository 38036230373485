import { ApiService } from "@/services/ApiService";

export default {
  getClients(options) {
    return ApiService.get(
      `/api/clients-list/options?page=${options.current_page}&page_size=${options.page_size}&search_text=${options.search_text}&sort_column=${options.sort.column}&sort_direction=${options.sort.direction}`
    );
  },
  getClientList() {
    return ApiService.get(
      `/api/client-list`
    );
  },
  addClient(payload) {
    return ApiService.post("/api/clients", payload);
  },
  editClient(payload) {
    return ApiService.put("/api/clients/" + payload.id, payload);
  },
  deleteClient(id) {
    return ApiService.delete("/api/clients/" + id);
  },

  deleteMutipleClients(clients_ids){
      return ApiService.post('/api/clients/delete/bulk',{
        selected_ids:clients_ids
      })
  },

  exportClientsExcel() {
    return ApiService.get("/api/clients/export/excel", {
      responseType: "blob",
    });
  },
};
