


export const state ={
    loading: false,
    error: null,
    user_profile:{}
}


export const actions ={
    
}