import { getError } from "../../helpers/errors"
import VendorService from "../../services/VendorService"


export const state = {
    loading: false,
    error:null,
    vendors:[],
    vendor_quotes:[]
}


export const actions ={
    getAllVendors({commit},options){
        commit("SET_LOADING", true)
        return new Promise((resolve,reject)=>{
            VendorService.getVendors(options)
            .then(({data})=>{
                commit("SET_VENDOR_QUOTES",data)
                resolve(data)
            })
            .catch((err)=>{
                commit("SET_ERROR", getError(err));
                reject(err);
            })
            .finally(()=> commit("SET_LOADING", false))
        })
    },

    addVendor({commit},payload){
        commit("SET_LOADING", true)
        return new Promise((resolve,reject)=>{
            VendorService.addVendor(payload)
            .then(({data})=>{
                resolve(data)
            })
            .catch((err)=>{
                commit("SET_ERROR", getError(err));
                reject(err);
            })
            .finally(()=> commit("SET_LOADING", false))
        })
    },

    editVendor({commit},payload){
        commit("SET_LOADING", true)
        return new Promise((resolve,reject)=>{
            VendorService.editVendor(payload)
            .then(({data})=>{
                resolve(data)
            })
            .catch((err)=>{
                commit("SET_ERROR", getError(err));
                reject(err);
            })
            .finally(()=> commit("SET_LOADING", false))
        })
    },

    deleteVendor({commit},id){
        commit("SET_LOADING", true)
        return new Promise((resolve,reject)=>{
            VendorService.deleteVendor(id)
            .then(({data})=>{
                resolve(data)
            })
            .catch((err)=>{
                commit("SET_ERROR", getError(err));
                reject(err);
            })
            .finally(()=> commit("SET_LOADING", false))
        })
    },

    exportVendorExcel({commit}){
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
          VendorService.exportVendorExcel()
            .then(({ data }) => {
              resolve(data);
            })
            .catch((err) => {
              reject(getError(err));
            });
        });
    },

    deleteMultipleVendors({ commit },vendors_ids) {
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
          VendorService.deleteMultipleVendors(vendors_ids)
            .then(({ data }) => {
              resolve(data);
            })
            .catch((err) => {
              reject(getError(err));
            });
        });
    },



   // Vendor Quotes
   getVendorQuotes({commit},options){
        commit("SET_LOADING", true)
        return new Promise((resolve,reject)=>{
            VendorService.getVendorQuotes(options)
            .then((response)=>{
                commit("SET_VENDORS",response)
                resolve(response)
            })
            .catch((err)=>{
                commit("SET_ERROR", getError(err));
                reject(err);
            })
            .finally(()=> commit("SET_LOADING", false))
        })
    },

    addVendorQuote({commit},payload){
        commit("SET_LOADING", true)
        return new Promise((resolve,reject)=>{
            VendorService.addVendorQuote(payload)
            .then(({data})=>{
                resolve(data)
            })
            .catch((err)=>{
                commit("SET_ERROR", getError(err));
                reject(err);
            })
            .finally(()=> commit("SET_LOADING", false))
        })
    },

    editVendorQuote({commit},payload){
        commit("SET_LOADING", true)
        return new Promise((resolve,reject)=>{
            VendorService.editVendorQuote(payload)
            .then(({data})=>{
                resolve(data)
            })
            .catch((err)=>{
                commit("SET_ERROR", getError(err));
                reject(err);
            })
            .finally(()=> commit("SET_LOADING", false))
        })
    },

    deleteVendorQuote({commit},id){
        commit("SET_LOADING", true)
        return new Promise((resolve,reject)=>{
            VendorService.deleteVendorQuote(id)
            .then(({data})=>{
                resolve(data)
            })
            .catch((err)=>{
                commit("SET_ERROR", getError(err));
                reject(err);
            })
            .finally(()=> commit("SET_LOADING", false))
        })
    },

    exportVendorQuotesExcel({commit}){
        commit("SET_LOADING", true)
        return new Promise((resolve, reject) => {
          VendorService.exportVendorQuotesExcel()
            .then(({ data }) => {
              resolve(data);
            })
            .catch((err) => {
              reject(getError(err));
            });
        });
    },
}


export const mutations ={
    SET_LOADING(state, loading) {
        state.loading = loading;
      },
    
      SET_ERROR(state, error) {
        state.error = error;
      },
      SET_VENDORS(state,data){
        state.vendors = data
      },
      SET_VENDOR_QUOTES(state,data){
          state.vendor_quotes = data
      }
     
}

export const getters ={
    loading: (state) => state.loading,
    error: (state) => state.error,
    vendors: (state) => state.vendors,
}