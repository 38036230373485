import { ApiService } from "./ApiService"


export default {
    getVendors(options){
        return ApiService.get( `/api/vendors-list?page=${options.current_page}&page_size=${options.page_size}&search_text=${options.search_text}&sort_column=${options.sort.column}&sort_direction=${options.sort.direction}`)
    },

    addVendor(payload){
        return ApiService.post('/api/vendors',payload)
    },
    
    editVendor(payload){
        return ApiService.put(`/api/vendors/${payload.id}`,payload)
    },

    deleteVendor(id){
        return ApiService.delete(`/api/vendors/${id}`)
    },

    exportVendorExcel(){
        return ApiService.get("/api/vendors/export/excel",{
            responseType:'blob'
          })
    },

    deleteMultipleVendors(vendors_ids){
        return ApiService.post(`/api/vendors/delete/bulk`, {
            selected_ids: vendors_ids
        })
      },

    // Vendor Quotes
    getVendorQuotes(options){
        return ApiService.get( `/api/vendor-quotes-list?page=${options.current_page}&page_size=${options.page_size}&search_text=${options.search_text}&sort_column=${options.sort.column}&sort_direction=${options.sort.direction}`)
    },

    addVendorQuote(payload){
        return ApiService.post('/api/vendor-quotes',payload)
    },
    
    editVendorQuote(payload){
        return ApiService.put(`/api/vendor-quotes/${payload.id}`,payload)
    },

    deleteVendorQuote(id){
        return ApiService.delete(`/api/vendor-quotes/${id}`)
    },
    exportVendorQuotesExcel(){
        return ApiService.get("/api/vendor-quotes/export/excel",{
            responseType:'blob'
          })
    },
}