import * as API from "./ApiService";
import router from "../router";

export const AuthService = {
  login,
  logout,
  getUser,
};

async function login(payload) {
  await API.ApiService.post("/api/sanctum/token", payload).then((response) => {
    //TODO: Encrypt Data

    if (response.data) {
      localStorage.setItem("user", JSON.stringify(response.data.token));
    }

    return response.data;
  });

  await this.getUser();
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");

  //TODO: check if not redundant
  localStorage.clear();

  // Fire a refresh
  //TODO: check if this is redundant
  window.location.reload();

  router.push({
    path: "/",
  });
}

function getUser() {
  return API.ApiService.get("/api/user");
}
