import ProductServices from "../../services/ProductServices";
import { getError } from "../../helpers/errors";

export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_ERROR(state, error) {
    state.error = error;
  },
}

export const actions = {
  getProducts({ commit },options) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      ProductServices.getProducts(options)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  getProductList({ commit }) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      ProductServices.getProductList()
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  addProduct({ commit },payload) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      ProductServices.addProduct(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  editProduct({ commit },payload) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      ProductServices.editProduct(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  deleteProduct({ commit },id) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      ProductServices.deleteProduct(id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  deleteMultipleProducts({ commit },products_ids) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      ProductServices.deleteMultipleProducts(products_ids)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  
  exportProductsExcel({ commit }) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      ProductServices.exportProductsExcel()
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },  
  // PRODUCT CATEGORIES
  getCatgeories({ commit },options) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      ProductServices.getCatgeories(options)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  getCategoryList({ commit }) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      ProductServices.getCategoryList()
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  addCategory({ commit },payload) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      ProductServices.addCategory(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  updateCategory({ commit },payload) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      ProductServices.updateCategory(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  getCategory({ commit },payload) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      ProductServices.getCategory(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  exportCategoryExcel({ commit }) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      ProductServices.exportCategoryExcel()
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  }, 
  deleteMultipleCategories({ commit },selected_ids) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      ProductServices.deleteMultipleCategories(selected_ids)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  deleteCategory({ commit },selected_ids) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      ProductServices.deleteCategory(selected_ids)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  // PRODUCT BRAND
  getBrands({ commit },options) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      ProductServices.getBrands(options)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  getBrandList({ commit }) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      ProductServices.getBrandList()
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  addBrand({ commit },payload) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      ProductServices.addBrand(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  updateBrand({ commit },payload) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      ProductServices.updateBrand(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  getBrand({ commit },payload) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      ProductServices.getBrand(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  exportBrandExcel({ commit }) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      ProductServices.exportBrandExcel()
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  deleteMultipleBrands({ commit },selected_ids) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      ProductServices.deleteMultipleBrands(selected_ids)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  deleteBrand({ commit },selected_ids) {
    commit("SET_LOADING", true)
    return new Promise((resolve, reject) => {
      ProductServices.deleteBrand(selected_ids)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
}
