import { ApiService } from "./ApiService";

export default {
    // USER PROFILE
    fetchUserProfile() {
        return ApiService.get("/api/user/profile");
    },

    fetchUserDetails() {
        return ApiService.get("/api/auth-user");
    },

    fetchUserPermission() {
        return ApiService.get("/api/user/permissions");
    },

    UpdateProfile(id) {
        return ApiService.get(`/api/update-profile/${id}`);
    },
    //USERS
    getUsers(options) {
        return ApiService.get(
            `/api/users-list?page=${options.current_page}&page_size=${options.page_size}&search_text=${options.search_text}&sort_column=${options.sort.column}&sort_direction=${options.sort.direction}`,
        );
    },
    getUserList() {
        return ApiService.get(
            `/api/user-list`,
        );
    },
    addUser(payload) {
        return ApiService.post(
            '/api/users',
            payload
        )
    },
    editUser(payload) {
        return ApiService.post(
            '/api/users/'+payload.id,
            payload.data
        )
    },
    activateUser(payload) {
        return ApiService.put(
            '/api/user/activate/'+payload.id,
            payload.data
        )
    },
    deleteUser(id){
        return ApiService.delete(
            '/api/users/'+id,
        )
    },
    deleteBulkUsers(selected_ids){
        return ApiService.post(
            '/api/users/delete/bulk',
            {selected_ids:selected_ids}
        )
    },
    exportUsers(){
        return ApiService.get(
            '/api/users/export/excel',{
                responseType:'blob'
            }
        )
    },
    // USER ROLES
    getRoles(options) {
        return ApiService.get(
            `/api/roles-list?page=${options.current_page}&page_size=${options.page_size}&search_text=${options.search_text}&sort_column=${options.sort.column}&sort_direction=${options.sort.direction}`,
        );
    },
    getRoleList() {
        return ApiService.get(
            `/api/role-list`,
        );
    },
    addRole(payload) {
        return ApiService.post(
            '/api/roles',
            payload
        )
    },
    editRole(payload) {
        return ApiService.put(
            '/api/roles/'+payload.id,
            payload
        )
    },
    deleteRole(id){
        return ApiService.delete(
            '/api/roles/'+id,
        )
    },
    deleteBulkRoles(selected_ids){
        return ApiService.post(
            '/api/roles/delete/bulk',
            {selected_ids:selected_ids}
        )
    },
    exportRoles(){
        return ApiService.get(
            '/api/roles/export/excel',{
                responseType:'blob'
            }
        )
    },
};
