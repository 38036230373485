import { ApiService } from "./ApiService"


export default {
    getProducts(options){
        return ApiService.get( `/api/products-list?page=${options.current_page}&page_size=${options.page_size}&search_text=${options.search_text}&sort_column=${options.sort.column}&sort_direction=${options.sort.direction}`)
    },
    getProductList(){
        return ApiService.get( `/api/product-list`)
    },
    addProduct(payload){
        return ApiService.post('/api/products',payload)
    },
    editProduct(payload){
        return ApiService.put(`/api/products/${payload.id}`,payload)
    },
    deleteProduct(id){
        return ApiService.delete(`/api/products/${id}`)
    },
    deleteMultipleProducts(products_ids){
      return ApiService.post(`/api/products/delete/bulk`, {
        products_ids: products_ids
      })
    },
    exportProductsExcel() {
      return ApiService.get( `/api/products/export/excel`,{
        responseType:'blob'
      })
    },
    // PROGRAMME CATEGORIES
    getCatgeories(options){
        return ApiService.get( `/api/categories-list?page=${options.current_page}&page_size=${options.page_size}&search_text=${options.search_text}&sort_column=${options.sort.column}&sort_direction=${options.sort.direction}`)
    },
    addCategory(payload){
        return ApiService.post('/api/categories',payload)
    },
    updateCategory(payload){
        return ApiService.put(`/api/categories/${payload.id}`,payload)
    },
    getCategory(id){
        return ApiService.get('/api/categories/' + id)
    },
    getCategoryList(){
        return ApiService.get( `/api/category-list`)
    },
    exportCategoryExcel() {
        return ApiService.get( `/api/categories/export/excel`,{
          responseType:'blob'
        })
    },
    deleteCategory(id){
        return ApiService.delete(`/api/categories/${id}`)
    },
    deleteMultipleCategories(selected_ids){
        return ApiService.post(`/api/categories/delete/bulk`, {
          selected_ids: selected_ids
        })
      },
    // PROGRAMME BRANDS
    getBrands(options){
        return ApiService.get( `/api/brands-list?page=${options.current_page}&page_size=${options.page_size}&search_text=${options.search_text}&sort_column=${options.sort.column}&sort_direction=${options.sort.direction}`)
    },
    getBrandList(){
        return ApiService.get( `/api/brand-list`)
    },
    addBrand(payload){
        return ApiService.post('/api/brands',payload)
    },
    updateBrand(payload){
        return ApiService.put(`/api/brands/${payload.id}`,payload)
    },
    getBrand(id){
        return ApiService.get('/api/brands/' + id)
    },
    exportBrandExcel() {
        return ApiService.get( `/api/brands/export/excel`,{
          responseType:'blob'
        })
    },
    deleteMultipleBrands(selected_ids){
        return ApiService.post(`/api/brands/delete/bulk`, {
            selected_ids: selected_ids
        })
    },
    deleteBrand(id){
        return ApiService.delete(`/api/brands/${id}`)
    },
}