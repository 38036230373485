import {ApiService} from "@/services/ApiService";


export default {
    getCurrencies(options) {
        return ApiService.get(
            `/api/currencies-list?page=${options.current_page}&page_size=${options.page_size}&search_text=${options.search_text}&sort_column=${options.sort.column}&sort_direction=${options.sort.direction}`,
        );
    },
    addCurrency(payload) {
        return ApiService.post(
            '/api/currencies',
            payload
        )
    },
    editCurrency(payload) {
        return ApiService.put(
            '/api/currencies/'+payload.id,
            payload
        )
    },
    deleteCurrency(id){
        return ApiService.delete(
            '/api/currencies/'+id,
        )
    },
    deleteMultipleProducts(currency_ids){
        return ApiService.post(`/api/currencies/delete/bulk`, {
            selected_ids: currency_ids
        })
      },
   

};
