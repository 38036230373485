import { getError } from "../../helpers/errors";
import RfqService from "../../services/RfqService";

export const state = {
  loading: false,
  error: null,
  rfqs: [],
};

export const actions = {
  getAllRFQs({ commit }, options) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      RfqService.getRFQs(options)
        .then(({ data }) => {
          commit("SET_RFQ", data);
          resolve(data);
        })
        .catch((err) => {
          commit("SET_ERROR", getError(err));
          reject(err);
        })
        .finally(() => commit("SET_LOADING", false));
    });
  },

  getSingleRFQ({ commit }, options) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      RfqService.getRFQ(options)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          commit("SET_ERROR", getError(err));
          reject(err);
        })
        .finally(() => commit("SET_LOADING", false));
    });
  },

  addRFQ({ commit }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      RfqService.addRFQ(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          commit("SET_ERROR", getError(err));
          reject(err);
        })
        .finally(() => commit("SET_LOADING", false));
    });
  },

  assignRFQ({ commit }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      RfqService.assignRFQs(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          commit("SET_ERROR", getError(err));
          reject(err);
        })
        .finally(() => commit("SET_LOADING", false));
    });
  },

  assignRFQUsers({ commit }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      RfqService.assignRFQUsers(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          commit("SET_ERROR", getError(err));
          reject(err);
        })
        .finally(() => commit("SET_LOADING", false));
    });
  },

  editRFQ({ commit }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      RfqService.updateRFQ(payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          commit("SET_ERROR", getError(err));
          reject(err);
        })
        .finally(() => commit("SET_LOADING", false));
    });
  },

  deleteRFQ({ commit }, id) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      RfqService.deleteRFQ(id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          commit("SET_ERROR", getError(err));
          reject(err);
        })
        .finally(() => commit("SET_LOADING", false));
    });
  },

  deleteMultipleRFQ({ commit }, rfqs_ids) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      RfqService.deleteMutipleRFQs(rfqs_ids)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
  //   RFQ STATUS
  getRFQStatusList({ commit }) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      RfqService.getRFQStatusList()
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },

  //   RFQ STATUS
  getRFQsResponse({ commit }, options) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      RfqService.getRFQsResponse(options)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },

  //   RFQ STATUS
  getRFQsDocs({ commit }, options) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      RfqService.getRFQsDocs(options)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(getError(err));
        });
    });
  },
};

export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },

  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_RFQ(state, data) {
    state.rfqs = data;
  },
};

export const getters = {
  loading: (state) => state.loading,
  error: (state) => state.error,
  rfqs: (state) => state.rfqs,
};
